//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationLoading from '@/components/Notification/Templates/NotificationLoading'
export default {
  data() {
    return {
      page: 1,
      notifications: [],
      isEmpty: true,
      isLoading: true,
      maxNumberOfPages: 1,
      timestamp: 0
    }
  },
  components: {
    EmptyMessage: () => import('@/components/EmptyMessage'),
    NotificationLoading,
    NotificationItem: () => import('@/components/Notification/Templates/NotificationItem')
  },
  methods: {
    initNotifs(){
      this.InitializeNotifications = setInterval(() => {
          if (this.$store.state.isLoggedIn === true) {
            if (this.timestamp == 0) {
              this.getNotifications()
            } else {
              this.getLatest()
            }
          } else {
            clearInterval(this.InitializeNotifications)
          }
        }, 10000);
    },
    getNotifications() {
      this.$http({
        url: 'notifications/retrieve?page=' + this.page,
        method: 'GET'
      }).then((response) => {
        if (response.data['status'] == 'notification_retrieved') {
          this.isEmpty = false;
          this.notifications = response.data['body']['notifications'];
          this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages'];
          this.timestamp = response.data['body']['timestamp'];
        } else {
          this.isEmpty = true;
          this.timestamp = response.data['body']['timestamp'];
        }
        this.isLoading = false;
      }).catch(() =>{
        this.isEmpty = true;
        this.isLoading = false;
      });
    },

    // Removes a notification on the server and on the local storage.
    removeNotification(id) {
      this.$http({
        url: 'notifications/delete/' + id,
        method: 'DELETE'
      }).then((response) => {
        if (response.data['status'] == 'delete_success') {
          for( var i = 0; i < this.notifications.length; i++){ 
            if (this.notifications[i].id === id) { 
              this.notifications.splice(i, 1); 
            }
          }
          if (this.notifications.length == 0) {
            this.isLoading = true;
            this.getNotifications();
          }            
        }
      });
    },

    // Gets the latest notification that will be appended on the first
    // page.
    getLatest() {
      this.$http({
        url: 'notifications/retrieve?latest=' + this.timestamp,
        method: 'GET'
      }).then((response) => {
        if (response.data['status'] == 'latest_notif_retrieved') {
          this.isEmpty = false;
          this.timestamp = response.data['body']['timestamp'];
          let newNotifs = response.data['body']['notifications'];
          newNotifs.forEach((notif) => {
            this.notifications.unshift(notif);

            // Inform user of new friend request.
            if (notif.notif_type == 1) {
              this.$q.notify({
                message: JSON.parse(notif.extra).name + ' wants to add you as their friend. Check your notifications.',
                color: 'green',
                avatar: (JSON.parse(notif.extra).profile_photo != 'not_available') ? JSON.parse(notif.extra).profile_photo : '@/assets/default_profile.png'
              })
            }
          })
        }
      });
    },

    // Marks the current notifications on storage as delivered.
    markAsDelivered() {
      //
    }
  },
  created() {
    this.isLoading = true;
    if(this.$store.state.isLoggedIn){
      this.initNotifs()
    }
  },
  computed: {
    countNewNotifications() {
      let count = 0;
      for( var i = 0; i < this.notifications.length; i++){ 
        if (this.notifications[i].is_delivered == null) {
          count++;
        }
      }
      return count;
    }
  }
}
